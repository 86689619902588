/* Custom scrollbar styles for Webkit browsers */
.scrollbar-custom::-webkit-scrollbar {
    height: 5px; /* Adjust scrollbar height */
  }
  
  .scrollbar-custom::-webkit-scrollbar-thumb {
    background: #334155; /* Scrollbar thumb color */
    border-radius: 4px; /* Round corners of the thumb */
  }
  
  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background: #334155; /* Color on hover */
  }
  
  .scrollbar-custom::-webkit-scrollbar-track {
    background: orange; /* Track color */
    border-radius: 4px; /* Round corners of the track */
  }
  