@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jockey+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jacquard+12+Charted&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Jacquard+12+Charted&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="radio"] {
  /* Hide the default radio button */
  @apply bg-white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #ccc;
  outline: none;

  cursor: pointer;
}

/* Custom styles for the radio button when checked */
input[type="radio"]:checked {
  @apply bg-white border-black;
}

/* Tick mark style */
input[type="radio"]:checked::before {
  @apply bg-[#5097A4]; /* Tailwind class for white background */
  content: ""; /* Create the tick mark */
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* const chawlaStyle = {
    animation: 'spin 2s linear infinite', // Apply rotation animation
  };
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */

.vertical-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.category-item {
  display: inline-block;
  padding: 1rem;
  white-space: nowrap;
  background: #f0f0f0;
  margin: 0 10px;
  border-radius: 8px;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* .horizontal-scroll::-webkit-scrollbar {
    display: none; 
  } */

/* Custom styles for sliders */
input[type="range"].slider {
  -webkit-appearance: none; /* Remove default styling */
  width: 100%;
  height: 6px; /* Track height */
  background: #d1d5db; /* Track color */
  outline: none; /* Remove outline */
  border-radius: 10px; /* Rounded corners */
  transition: background 0.3s ease-in-out; /* Smooth transition */
}

input[type="range"].slider:hover {
  background: #a3a3a3; /* Track color on hover */
}

input[type="range"].slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 24px; /* Thumb width */
  height: 24px; /* Thumb height */
  background: rgb(61, 61, 137); /* Thumb color */
  border-radius: 50%; /* Rounded thumb */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow for thumb */
  cursor: pointer; /* Pointer cursor */
  transition: transform 0.2s ease-in-out; /* Smooth transition */
}

input[type="range"].slider:active::-webkit-slider-thumb {
  transform: scale(1.2); /* Scale thumb on active */
}

input[type="range"].slider::-moz-range-thumb {
  width: 24px; /* Thumb width */
  height: 24px; /* Thumb height */
  background: #fbbf24; /* Thumb color */
  border-radius: 50%; /* Rounded thumb */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow for thumb */
  cursor: pointer; /* Pointer cursor */
  transition: transform 0.2s ease-in-out; /* Smooth transition */
}

input[type="range"].slider:active::-moz-range-thumb {
  transform: scale(1.2); /* Scale thumb on active */
}

input[type="range"].slider::-ms-thumb {
  width: 24px; /* Thumb width */
  height: 24px; /* Thumb height */
  background: #fbbf24; /* Thumb color */
  border-radius: 50%; /* Rounded thumb */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow for thumb */
  cursor: pointer; /* Pointer cursor */
  transition: transform 0.2s ease-in-out; /* Smooth transition */
}

input[type="range"].slider:active::-ms-thumb {
  transform: scale(1.2); /* Scale thumb on active */
}
/* src/AuthForm.css */

:root {
  --primary-color: #4ea685;
  --secondary-color: #57b894;
  --black: #000000;
  --white: #ffffff;
  --gray: #efefef;
  --gray-2: #757575;

  --facebook-color: #4267b2;
  --google-color: #db4437;
  --twitter-color: #1da1f2;
  --insta-color: #e1306c;
}

.container.sign-in .form.sign-in,
.container.sign-in .form.sign-up,
.container.sign-up .form.sign-in,
.container.sign-up .form.sign-up {
  transition: transform 0.5s ease-in-out;
}

.content-row .sign-in,
.content-row .sign-up {
  transition: transform 1s ease-in-out;
}

.container.sign-in .content-row .sign-in {
  transform: translateX(0);
}

.container.sign-in .content-row .sign-up {
  transform: translateX(100%);
}

.container.sign-up .content-row .sign-in {
  transform: translateX(-100%);
}

.container.sign-up .content-row .sign-up {
  transform: translateX(0);
}

/* index.css */

@keyframes slide {
  0% {
    width: 50%;
    border-bottom-right-radius: 100%;
    left: 0;
  }

  50% {
    width: 100%;
    /* right:0; */
    border-radius: 0;
  }
  50% {
    width: 100%;
    /* right:0; */
    border-radius: 0;
  }

  100% {
    border-top-left-radius: 100%;
    width: 50%;
    right: 0;
  }
}

.animate-slide {
  animation: slide 1s forwards;
}

@keyframes slide2 {
  0% {
    border-top-left-radius: 100%;
    width: 50%;
    right: 0;
  }
  50% {
    width: 100%;
    border-radius: 0;
    right: 0;
  }
  100% {
    left: 0;
    border-bottom-right-radius: 100%;
    width: 50%;
  }
}

.animate-slide2 {
  animation: slide2 1s forwards;
}

@keyframes signin {
  0% {
    transform: scale(0);
    /* transform: scale(0); */
    opacity: 0;
    visibility: hidden;
  }
  80% {
    transform: scale(0);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: scale(1);
  }
}

.animate-signin {
  animation: signin 1s forwards;
}

@keyframes signup {
  0% {
    transform: scale(0);
    /* transform: scale(0); */
    opacity: 0;
    visibility: hidden;
  }
  80% {
    transform: scale(0);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: scale(1);
  }
}

.animate-signup {
  animation: signup 1s forwards;
}

@keyframes joinus {
  0% {
    transform: translateX(-400%);
    opacity: 0;
    visibility: hidden;
  }
  50% {
    transform: translateX(-360%);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}

.animate-joinus {
  animation: joinus 1200ms forwards;
}
@keyframes joinus1 {
  0% {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translateX(-200%);

    opacity: 0;
    visibility: hidden;
  }
}

.animate-joinus1 {
  animation: joinus1 1s forwards;
}

/* welcome */

@keyframes wel {
  0% {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  }


  100% {
    transform: translateX(300%);
    opacity: 0;
    visibility: hidden;
  }
}

.animate-wel {
  animation: wel 1s forwards;
}

@keyframes wel1 {
  0% {
    transform: translateX(300%);
    opacity: 0;
    visibility: hidden;
  }
  50% {
    transform: translateX(300%);
    opacity: 0;
    visibility: hidden
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}
.animate-wel1 {
  animation: wel1 1200ms  forwards;
}

.hidden-details {
  display: none;
}

.hover\:show-details:hover .hidden-details {
  display: block;
}


/* Scrollbar style for the container */
.w-full::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

/* Track (scrollbar background) */
.w-full::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 10px;
}


.w-full::-webkit-scrollbar-thumb {
  background-color: #5097A4; 
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
}


.w-full::-webkit-scrollbar-thumb:hover {
  background-color: #5097A4;
}


.overflow-y-scroll {
  scrollbar-width: thin; 
  scrollbar-color: #107D9F #f1f1f1;
}


/* index.css */
.hasHighlight .slice {
  filter: saturate(50%);
  opacity: 0.2;
}

.hasHighlight .slice:hover {
  filter: saturate(100%);
  opacity: 1;
}



.hexagon {
  clip-path: polygon(
    25% 5.77%, 75% 5.77%, 100% 50%, 
    75% 94.23%, 25% 94.23%, 0% 50%
  );
}

.certificates {
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100%; */
}

@media (max-width: 640px) {
  .certificates {
    background-image: none;
    background-color: white;
  }
}
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.profile-container {
  width: 100%;
  padding: 0.7rem 1rem; /* py-2 (top and bottom) + px-3 (left and right) */
  margin: 0 auto; /* mx-auto (left and right) */
}



.typewriter-cursor {
  display: inline-block;
  width: 2px; /* Width of the cursor */ /* Color of the cursor */
  margin-left: 2px; /* Spacing from text */
  animation: blink 0.75s steps(1) infinite; /* Blink animation with smooth steps */
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}



/* Add this CSS to your styles file or a <style> tag in your component */
/* Add this CSS to your styles file or a <style> tag in your component */
@keyframes bounceUp {
  0% {
    transform: translateY(0); /* Start at original position */
  }
  50% {
    transform: translateY(-5px); /* Move up slightly */
  }
  100% {
    transform: translateY(0); /* Return to original position */
  }
}

.animated-arrow {
  animation: bounceUp 1s ease-in-out infinite; /* Apply the animation */
}

/* Custom Scrollbar Styling */
.scrollbar-customXy::-webkit-scrollbar {
  width: 5px; /* Vertical scrollbar width */
  height: 5px; /* Horizontal scrollbar height */
}

.scrollbar-customXy::-webkit-scrollbar-thumb {
  background: #5097A4; /* Scrollbar thumb color */
  border-radius: 4px; /* Round corners of the thumb */
}

.scrollbar-customXy::-webkit-scrollbar-thumb:hover {
  background: #5097A4; /* Color on hover */
}

.scrollbar-customXy::-webkit-scrollbar-track {
  background: rgb(35, 34, 34); /* Track color */
  border-radius: 4px; /* Round corners of the track */
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.animate-pulse-scale {
  animation: scaleAnimation 1s ease-in-out infinite;
}

.slick-prev,
.slick-next {
  color: black !important; 
  font-size: 24px;
  z-index: 10; 
}

.slick-prev::before,
.slick-next::before {
  color: black !important; 
  font-size: 24px; 
}


.admin-input {
  background-color: #f9fafb;
  border: 1px solid #d1d5db; 
  color: #1f2937; 
  font-size: 0.875rem; 
  border-radius: 0.75rem; 
  padding: 0.5rem 0.75rem; 
  width: 100%; 
  box-shadow: 0px 0px 7.6px 0px #00000040 inset;
}


/* Reset Button Style */
.reset-btn {
  display: flex;
  gap: 0.25rem; 
  align-items: center; 
  font-size: 0.875rem; 
  padding: 0.5rem 0.75rem; 
  border: 1px solid #b91c1c; 
  color: #b91c1c; 
  border-radius: 0.75rem; 
  background-color: #fee2e2; 
  transition: background-color 0.15s ease, color 0.15s ease; 
}

.reset-btn:hover {
  background-color: #dc2626; 
  color: #ffffff; 
}


.excel-btn {
  display: flex;
  gap: 0.25rem; 
  align-items: center; 
  font-size: 0.875rem; 
  padding: 0.5rem 0.75rem; 
  border: 1px solid #00cb4a;
  color: #00cb4a; 
  border-radius: 0.75rem; 
  background-color: #d1fae5; 
  transition: background-color 0.15s ease, color 0.15s ease; 
}

.excel-btn:hover {
  background-color: #22c55e; 
  color: #ffffff; 
}

.input-style {
  @apply w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border-[1px] border-slate-300 rounded-md px-3 py-2 transition duration-300 ease-in-out focus:outline-none focus:border-turkishBlue-default ring-4 ring-transparent focus:ring-[#cff2f8];
}

@keyframes gradient-border {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.no-scroll {
    overflow: hidden;
    height: 100vh;
}
